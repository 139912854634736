import {createApp} from 'vue'
import App from './App.vue'
import {i18n} from "./i18n/index";
import store from './store/main'
import Maska from 'maska'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {createRouter, createWebHistory} from 'vue-router'

//const BASE_PATH = 'http://localhost:8080';
//Test
const BASE_PATH   = 'https://id.intend.uz';
const TESTRELOGIN = 'https://reg.intend.uz/intend-id-relogin';
//Production
//const BASE_PATH = 'https://id.intend.uz';

const router = createRouter({
    history: createWebHistory(),
    routes : [
        {path: '/', component: App}
    ]
})

const app = createApp(App).use(VueAxios, axios).use(store).use(router).use(i18n).use(Maska);

app.provide('axios', app.config.globalProperties.axios)
app.provide('$api_base_path', BASE_PATH)
app.provide('$testReLogin', TESTRELOGIN)
/*app.config.errorHandler = (err, instance, info) => {
 console.log(err);
 console.log(instance);
 console.log(info);
 }*/

app.mount('#intend_app')